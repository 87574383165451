export const seoKeyWords = [
  "developer",
  "clothing",
  "developer shirts",
  "developer t-shirts",
  "coding shirts",
  "coding t-shirts",
  "programmer shirts",
  "programmer t-shirts",
]
